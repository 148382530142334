import firebase from 'firebase/app'
import 'firebase/firebase-messaging'

var firebaseConfig = {
  apiKey: "AIzaSyCwIWTbnY77TOZKS7plG26uoYWKStRmYmo",
  authDomain: "sattva-ats-fcm.firebaseapp.com",
  projectId: "sattva-ats-fcm",
  storageBucket: "sattva-ats-fcm.appspot.com",
  messagingSenderId: "1045368627951",
  appId: "1:1045368627951:web:4f717631e996c6cddcb11a",
  measurementId: "G-XC533MGYTQ"
};

//firebase.initializeApp(firebaseConfig)

//export default firebase.messaging()
