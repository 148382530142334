import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'




import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// Axios Mock Adapter
import '@/@fake-db/db'

import firebaseMessaging from './firebase'
//import ExportExcelSheetVueJs from "export-excel-sheet-vue-js";
import JsonExcel from "vue-json-excel";
 
Vue.component("downloadExcel", JsonExcel);
if(process.env.NODE_ENV === "production") {
  Vue.prototype.$messaging = firebaseMessaging
}
//Vue.use(ExportExcelSheetVueJs);
// BSV Plugin Registration

import { abilitiesPlugin } from '@casl/vue';
Vue.use(abilitiesPlugin, store.state.ability);


Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)
//serviceWorker.unregister()



// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
