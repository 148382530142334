export default [
  {
    path: '/dashboard/admin',
    name: 'dashboard-admin',
    component: () => import('@/views/dashboard/admin/Admin.vue'),
    meta: {
      resource: 'admin_dashboard',
      action: 'read'
    },
  },
  {
    path: '/dashboard/revenue',
    name: 'dashboard-revenue',
    component: () => import('@/views/dashboard/revenue/Revenue.vue'),
    meta: {
      resource: 'revenue_dashboard',
      action: 'read'
    },
  },
  {
    path: '/dashboard/main',
    name: 'dashboard-main',
    component: () => import('@/views/dashboard/main/Recruiter.vue'),
    meta: {
      resource: 'main_dashboard',
      action: 'read'
    },
  },
  {
    path: '/dashboard/power-bi/',
    name: 'dashboard-power-bi',
    component: () => import('@/views/dashboard/power-bi/PowerBI.vue'),
    meta: {
      resource: 'powerbi',
      action: 'read',
      pageTitle: 'Power BI Dashboards',
    },
  },

]
