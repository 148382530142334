import ability from '@/libs/acl/ability'

export default [
    {
        header: 'Sattva Infra Menu',
    },
    {
      title: 'Sattva Infra',
      icon: 'HomeIcon',
      resource: 'infra',
      action: ability.can('read', 'infra') === true ? 'read' : 'manage',
      children: [
        {
          title: 'Infra Tracker',
          route: 'infra-inquiry',
        },
        // {
        //   title: 'Cost Sheet',
        //   route: 'infra-cost-sheet',
        // },
        // {
        //   title: 'Offer',
        //   route: 'infra-offer',
        // },
        // {
        //   title: 'LOI',
        //   route: 'infra-loi',
        // },
        // {
        //   title: 'PO',
        //   route: 'infra-po',
        // }
      ],
    },
  ]
  