import axios from 'axios';
import AuthService from './auth-header';
// /*dev*/const API_URL = 'http://192.168.1.34:5000/api/';
// /*prod*/ // const API_URL = 'https://ats.sattvahuman.in/api/';

let API_URL = ''
if (process.env.NODE_ENV === "production") {
    API_URL = 'https://ats.sattvahuman.in/api/';
} else {
    API_URL = 'http://192.168.1.34:5000/api/';
}
class TaskService {
    async addTask(data) {
        console.log(data)
        try {
            const res = await axios.post(API_URL + 'tasks', data,
        { 
            headers: AuthService.authHeader()
        });
        console.log(res.data)
        return res.data;
        }
        catch (err) {
            console.log(err.response)
            return err.response;
        }
        
    }

    async getAllTasks() {
        try {
            const res = await axios.get(API_URL + 'tasks',
            { 
                headers: AuthService.authHeader()
            });
            console.log(res.data)
            return res.data
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }

    async updateTask(id, data) {
        //console.log(id)
        //console.log(AuthService.authHeader())
        try {
            const res = await axios.put(API_URL + 'tasks/' + id, data,
            { 
                headers: AuthService.authHeader()
            });
            console.log(res.data)
            return res.data
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }
    async updateTaskDelete(id) {
        //console.log(id)
        //console.log(AuthService.authHeader())
        const data = {
            isDeleted: true
        }
        try {
            const res = await axios.put(API_URL + 'tasks/delete/' + id, data,
            { 
                headers: AuthService.authHeader()
            });
            console.log(res.data)
            return res.data
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }

    async deleteTask(id) {
        try {
            const res = await axios.delete(API_URL + 'tasks/' + id,
            { 
                headers: AuthService.authHeader()
            });
            console.log(res.data)
            return res.data
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }

}

export default new TaskService();