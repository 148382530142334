export default [
  {
    path: '/access-control',
    name: 'access-control',
    component: () => import('@/views/extensions/acl/AccessControl.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
    },
  },
  // {
  //   path: '/nav-hor',
  //   name: 'navigation-hor',
  //   component: () => import('@/navigation/horizontal/index'),
  //   meta: {
  //     resource: 'NAV',
  //     action: 'manage',
  //   },
  // },
  {
    path: '/nav-ver',
    name: 'navigation-ver',
    component: () => import('@/navigation/vertical/index'),
    meta: {
      resource: 'NAV',
      action: 'manage',
    },
  },
  {
    path: '/nav-ver',
    name: 'navigation-ver',
    component: () => import('@/navigation/horizontal/index'),
    meta: {
      resource: 'NAV',
      action: 'manage',
    },
  },
]
