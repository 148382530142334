import ability from '@/libs/acl/ability'

// console.log('check ability admin dashboard: ', ability)
// console.log('check ability revenue dashboard: ', ability.can('read', 'RevenueDashboard'))
// console.log('check ability main dashboard: ', ability.can('read', 'MainDashboard'))
// console.log('check ability power bi dashboard: ', ability.can('read', 'MainDashboard'))

export default [
  {
    title: 'Dashboards',
    icon: 'HomeIcon',
    resource: 'main_dashboard' || 'dashboard',
    action: 'read',
    children: [
      {
        title: 'Admin',
        route: 'dashboard-admin',
        // visible: () => ability.can('read', 'AdminDashboard'),
        resource: 'admin_dashboard',
        action: ability.can('read', 'admin_dashboard') === true ? 'read' : 'manage'
      },
      {
        title: 'Revenue',
        route: 'dashboard-revenue',
        resource: 'revenue_dashboard',
        action: ability.can('read', 'revenue_dashboard') === true ? 'read' : 'manage'        
      },
      {
        title: 'Main',
        route: 'dashboard-main',
        resource: 'main_dashboard',
        action: ability.can('read', 'main_dashboard') === true ? 'read' : 'manage'
      },
      {
        title: 'Power BI',
        route: 'dashboard-power-bi',
        resource: 'powerbi',
        action: ability.can('read', 'powerbi') === true ? 'read' : 'manage'
      }
    ],
  },
]
