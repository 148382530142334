import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import apps from './routes/apps'
import dashboard from './routes/dashboard'
import infra from './routes/infra'
// import uiElements from './routes/ui-elements/index'
import pages from './routes/pages'
// import chartsMaps from './routes/charts-maps'
// import formsTable from './routes/forms-tables'
import others from './routes/others'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  // scrollBehavior() {
  //   return { x: 0, y: 0 }
  // },
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if(savedPosition) {
          console.log('saved postion',savedPosition, from)
          //window.addEventListener('scroll', savedPosition);
          localStorage.setItem('lastPosition', JSON.stringify(savedPosition))
          localStorage.setItem('from', JSON.stringify(from))
          resolve(savedPosition)
          
        } else {
          const savedPosition = { x: 0, y: 0 }
          localStorage.setItem('lastPosition', savedPosition)
          resolve(savedPosition)
        }
      }, 500)
    })
  },
  routes: [
    { path: '/', redirect: { name: 'dashboard-main' } },
    ...apps,
    ...dashboard,
    ...pages,
    ...infra,
    //  ...chartsMaps,
    // ...formsTable,
    // ...uiElements,
    ...others,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, from, next) => {
  const isLoggedIn = isUserLoggedIn()
  console.log('is loogedIN', isLoggedIn)
  //const isLoggedIn = localStorage.getItem('userData')
  //console.log("router isloogedin", isLoggedIn)
  //console.log("can navigate to", to)
  //const jwtPayload = JSON.parse(window.atob(isLoggedIn.split('.')[1]))
  //console.log(jwtPayload.exp);
  console.log("router hostory", to, from)
  const lastPageUrl = window.sessionStorage.getItem('lastPage');
  
  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn && lastPageUrl !== '/login') {
      return next({ name: 'auth-login' })
    }

    // If logged in => not authorized
    //console.log("before Calling not authorized")
    return next({ name: 'misc-not-authorized' })
  }
  //console.log("meta Redirect login", to.meta.redirectIfLoggedIn)
  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    console.log("meta userData", userData)
    next(getHomeRouteForLoggedInUser(userData ? userData.roles[0] : null))
  } 
  window.sessionStorage.setItem('scrollPosition', window.pageYOffset)
  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
  const scrollPosition = +window.sessionStorage.getItem('scrollPosition')
  if (scrollPosition) {
    window.scrollTo(0, scrollPosition)
    window.sessionStorage.removeItem('scrollPosition')
  }
})

export default router