export const initialAbility = [
  {
    action: 'read',
    subject: 'auth',
  },
  // {
  //   action: 'manage',
  //   subject: 'all'
  // }
]

export const _ = undefined
