import Vue from 'vue'
import Vuex from 'vuex'
import { Ability } from '@casl/ability'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
// import { auth } from './auth-module'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    // auth
    // 'app-ecommerce': ecommerceStoreModule,
  },
  state: {
    ability: new Ability([])
  },
  mutations: {
    updateAbilities(state, rules) {
      state.ability.update(rules);
    }
  },
  actions: {
    fetchPermissions({ commit }, userPermissions) {
      // This action should fetch permissions from the server or handle them as needed
      // Convert userPermissions to CASL rules
      const rules = convertPermissionsToCASLRules(userPermissions);
      commit('updateAbilities', rules);
    }
  },
  strict: process.env.DEV,
})


//vuex old code
// import Vue from 'vue'
// import Vuex from 'vuex'

// // Modules
// // import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
// import app from './app'
// import appConfig from './app-config'
// import verticalMenu from './vertical-menu'
// //import { auth } from './auth-module'

// Vue.use(Vuex)

// export default new Vuex.Store({
//   modules: {
//     app,
//     appConfig,
//     verticalMenu,
//     //auth
//     // 'app-ecommerce': ecommerceStoreModule,
//   },
//   strict: process.env.DEV,
// })

