class AuthService {

 authHeader() {
    let user = JSON.parse(localStorage.getItem('userData'));
    let accessToken = localStorage.getItem('accessToken');
    if (user && accessToken) {
      // for Node.js Express back-end
      return { 'x-access-token': accessToken, "Content-Type": "application/json" };
    } else {
      return {};
    }
  }

authHeaderForm() {
    let user = JSON.parse(localStorage.getItem('userData'));
    let accessToken = localStorage.getItem('accessToken');
    if (user && accessToken) {
      // for Node.js Express back-end
      return { 
        'x-access-token': accessToken,
        'Content-Type': 'multipart/form-data'
      };
    } else {
      return {};
    }
  }  
}

export default new AuthService();
