export default [
    {
      path: '/infra/inquiry',
      name: 'infra-inquiry',
      component: () => import('@/views/infra/inquiry/InfraInquiry.vue'),
      meta: {
        resource: 'Infra',
        pageTitle: 'Infra Inquiry',
      },
    },
    {
        path: '/infra/inquiry/add',
        name: 'infra-add-inquiry',
        component: () => import('@/views/infra/inquiry/AddInquiry.vue'),
        meta: {
            resource: 'Infra',
            pageTitle: 'Add Inquiry',
            breadcrumb: [
                {
                  text: 'Infra Inquiry',
                  to: { name: 'infra-inquiry' }
                },
                {
                  text: 'Add Inquiry',
                  active: true,
                },
            ],
        },
    },
    {
        path: '/infra/inquiry/edit/:id',
        name: 'infra-edit-inquiry',
        component: () => import('@/views/infra/inquiry/EditInquiry.vue'),
        meta: {
            resource: 'Infra',
            pageTitle: 'Edit Inquiry',
            breadcrumb: [
                {
                  text: 'Infra Inquiry',
                  to: { name: 'infra-inquiry' }
                },
                {
                  text: 'Edit Inquiry',
                  active: true,
                },
            ],
        },
    },
    {
        path: '/infra/tracker/:id',
        name: 'infra-tracker',
        component: () => import('@/views/infra/InfraTracker.vue'),
        meta: {
            resource: 'Infra',
            pageTitle: 'Infra Tracker',
        },
    },    
    // {
    //   path: '/infra/cost-sheet',
    //   name: 'infra-cost-sheet',
    //   component: () => import('@/views/infra/InfraCostSheet.vue'),
    //   meta: {
    //     resource: 'Infra',
    //     pageTitle: 'Infra Cost Sheet',
    //   },
    // },
    // {
    //   path: '/infra/offer',
    //   name: 'infra-offer',
    //   component: () => import('@/views/infra/InfraOffer.vue'),
    //   meta: {
    //     resource: 'Infra',
    //     pageTitle: 'Infra Offer',
    //   },
    // },
    // {
    //   path: '/infra/loi/',
    //   name: 'infra-loi',
    //   component: () => import('@/views/infra/InfraLOI.vue'),
    //   meta: {
    //     resource: 'Infra',
    //     pageTitle: 'Infra LOI',
    //   },
    // },
    // {
    //     path: '/infra/po/',
    //     name: 'infra-po',
    //     component: () => import('@/views/infra/InfraPO.vue'),
    //     meta: {
    //       resource: 'Infra',
    //       pageTitle: 'Infra PO',
    //     },
    //   },
  
  ]
  