import mock from '@/@fake-db/mock'
// import { paginateArray, sortCompare } from '@/@fake-db/utils'

/* eslint-disable global-require */
const data = {
  jobs: [
    {
      jobId: 'SHJ-1001',
      positionName: 'Sr. Java Developer',
      client: 'Accenture',
      jobDomain: 'IT',
      minCTC: 10,
      maxCTC: 12,
      desiredSkills: 'A, B, C',
      mandatorySkills: 'X, Y, Z',
      jobDescription: '',
      /* location: [{ city: 'Pune', state: 'Maharashtra', country: 'India' }, { city: 'Mumbai', state: 'Maharashtra', country: 'India' }], */
      location: 'Pune, Mumbai',
      noticePeriod: 90,
      noOfPositions: 10,
      open: 10,
      closed: 0,
      jobRole: 'Senior Consultant',
      band: '3',
      status: 'Active',
      attachment: ['A', 'B'],
      createDate: '',
      modifyDate: '',
      recruiters: [{ id: 1, name: 'Nikita' }, { id: 2, name: 'Ritesh' }],
      poc: 'ABC',
      comments: 'HI there',
      experience: '5',
    },
    {
      jobId: 'SHJ-1002',
      positionName: 'Business Analyst',
      client: 'Infosys',
      jobDomain: 'IT',
      minCTC: 10,
      maxCTC: 12,
      desiredSkills: 'A, B, C',
      mandatorySkills: 'X, Y, Z',
      jobDescription: '',
      /* location: [{ city: 'Pune', state: 'Maharashtra', country: 'India' }, { city: 'Mumbai', state: 'Maharashtra', country: 'India' }], */
      location: 'Pune, Kolkatta',
      noticePeriod: 90,
      noOfPositions: 10,
      open: 10,
      closed: 0,
      jobRole: 'BA Lead',
      band: '4',
      status: 'Active',
      attachment: ['A', 'B'],
      createDate: '',
      modifyDate: '',
      recruiters: [{ id: 1, name: 'Nikita' }, { id: 2, name: 'Yogesh' }],
      poc: 'XYZ',
      comments: 'HI there',
      experience: '5',
    },
  ],
}
/* eslint-enable */

// ------------------------------------------------
// GET: Return Users
// ------------------------------------------------
mock.onGet('/jobs/data').reply(() => [200, data])
