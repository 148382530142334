import mock from '@/@fake-db/mock'
import { paginateArray, sortCompare } from '@/@fake-db/utils'

/* eslint-disable global-require */
const data = {
  recruiters: [
    {
      id: 1,
      fullName: 'Ritesh Tiwari',
      role: 'IT Advisor',
      emp_id: 'SHR-1003',
      doj: '10/03/2020',
      contact: '(479) 232-9151',
      email: 'ritesh@sattvahuman.com',
      location: 'Pune',
      branch: 'Pune',
      status: 'inactive',
      teamlead_id: '',
      mgr_id: '',
      profiles: '10 Lacs',
      closures: 10,
      task_ids: [1, 2, 4],
      activity_ids: [3, 4, 7],
      job_assigne_ids: [10, 20, 30],
    },
    {
      id: 2,
      fullName: 'Yogesh Tiwari',
      role: 'Operations Head',
      emp_id: 'SHR-1001',
      doj: '10/03/2020',
      contact: '(479) 232-9151',
      email: 'yogesh@sattvahuman.com',
      location: 'Pune',
      branch: 'Pune',
      status: 'active',
      teamlead_id: '',
      mgr_id: '',
      /* profiles: {
        shared: 30, shortlisted: 20, offered: 10, joined: 5, offer_drop: 5, rejected: 10, revenue_gen: '100Lac',
      },
      closures: { positive: 10, negative: 10 }, */
      profiles: '1 crore',
      closures: 10,
      task_ids: [1, 2, 4],
      activity_ids: [3, 4, 7],
      job_assigne_ids: [10, 20, 30],
    },
    {
      id: 3,
      fullName: 'Nikita Tiwari',
      role: 'HR',
      emp_id: 'SHR-1002',
      doj: '10/03/2020',
      contact: '(479) 232-9151',
      email: 'nikita@sattvahuman.com',
      location: 'Pune',
      branch: 'Pune',
      status: 'active',
      teamlead_id: '',
      mgr_id: '',
      /* profiles: {
        shared: 30, shortlisted: 20, offered: 10, joined: 5, offer_drop: 5, rejected: 10, revenue_gen: '100Lac',
      },
      closures: { positive: 10, negative: 10 }, */
      profiles: '50 Lacs',
      closures: 20,
      task_ids: [1, 2, 4],
      activity_ids: [3, 4, 7],
      job_assigne_ids: [10, 20, 30],
    },
  ],
}
/* eslint-enable */

// ------------------------------------------------
// GET: Return Recruiters
// ------------------------------------------------
mock.onGet('/apps/recruiter/recruiters').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const {
    q = '',
    perPage = 10,
    page = 1,
    sortBy = 'id',
    sortDesc = false,
    role = null,
    // plan = null,
    status = null,
  } = config.params
  /* eslint-enable */

  const queryLowered = q.toLowerCase()
  const filteredData = data.recruiters.filter(
    recruiter =>
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      (recruiter.emp_id.toLowerCase().includes(queryLowered) || recruiter.fullName.toLowerCase().includes(queryLowered)) &&
      recruiter.role === (role || recruiter.role) &&
      // recruiter.currentPlan === (plan || recruiter.currentPlan) &&
      recruiter.status === (status || recruiter.status),
  )
  /* eslint-enable  */

  const sortedData = filteredData.sort(sortCompare(sortBy))
      console.log("sortedData",sortedData)
      console.log("paginate array",paginateArray(sortedData, perPage, page))
      console.log("total",filteredData.length)
      
  if (sortDesc) sortedData.reverse()

  return [
    200,
    {
      recruiters: paginateArray(sortedData, perPage, page),
      total: filteredData.length,
    },
  ]
})

// ------------------------------------------------
// POST: Add new recruiter
// ------------------------------------------------
mock.onPost('/apps/recruiter/recruiters').reply(config => {
  // Get event from post data
  const { recruiter } = JSON.parse(config.data)

  // Assign Status
  recruiter.status = 'active'

  const { length } = data.recruiters
  let lastIndex = 0
  if (length) {
    lastIndex = data.recruiters[length - 1].id
  }
  recruiter.id = lastIndex + 1

  data.recruiters.push(recruiter)

  return [201, { recruiter }]
})

// ------------------------------------------------
// GET: Return Single Recruiter
// ------------------------------------------------
mock.onGet(/\/apps\/recruiter\/recruiters\/\d+/).reply(config => {
  // Get event id from URL
  let recruiterId = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  recruiterId = Number(recruiterId)

  const recruiterIndex = data.recruiters.findIndex(e => e.id === recruiterId)
  const recruiter = data.recruiters[recruiterIndex]

  if (recruiter) return [200, recruiter]
  return [404]
})
