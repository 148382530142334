import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = null
/*const data = {
  /*statisticsItems: [
    {
      icon: 'TrendingUpIcon',
      color: 'light-primary',
      title: '500',
      subtitle: 'total',
      customClass: 'mb-2 mb-xl-0',
    },
    {
      icon: 'UserIcon',
      color: 'light-info',
      title: '300',
      subtitle: 'Shortlisted',
      customClass: 'mb-2 mb-xl-0',
    },
    {
      icon: 'BoxIcon',
      color: 'light-danger',
      title: '100',
      subtitle: 'Rejected',
      customClass: 'mb-2 mb-sm-0',
    },
    {
      icon: 'DollarSignIcon',
      color: 'light-success',
      title: '100',
      subtitle: 'Joined',
      customClass: '',
    },
  ],
  revenueItems: [
    {
      icon: 'TrendingUpIcon',
      color: 'light-primary',
      title: '30',
      subtitle: 'Active clients',
      customClass: 'mb-2 mb-sm-0',
    },
    {
      icon: 'UserIcon',
      color: 'light-info',
      title: '100',
      subtitle: 'Active Positions',
      customClass: '',
    },
  ],
  clientClosureItems: [
    {
      name: 'Evalueserve',
      joined: 71,
      pending: 4,
    },
    {
      name: 'Genpact',
      joined: 198,
      pending: 5,
    },
    {
      name: 'GVR',
      joined: 7,
      pending: 0,
    },
    {
      name: 'KPMG',
      joined: 55,
      pending: 1,
    },
    {
      name: 'WNS',
      joined: 2,
      pending: 0,
    },
    {
      name: 'Paytm',
      joined: 5,
      pending: 0,
    },
    {
      name: 'Goldman Sachs',
      joined: 4,
      pending: 1,
    },
    {
      name: 'Infosys',
      joined: 5,
      pending: 1,
    },
    {
      name: 'Tech Mahindra',
      joined: 6,
      pending: 1,
    },
    {
      name: 'Markets & Markets',
      joined: 2,
      pending: 0,
    },
    {
      name: 'Amazon',
      joined: 4,
      pending: 0,
    },
    {
      name: 'Affinity',
      joined: 4,
      pending: 2,
    },
    {
      name: 'Accenture',
      joined: 2,
      pending: 1,
    },
  ],
  teamClosureItems: [
    {
      recruiter: 'Aakanksha',
      joined: 19,
      pending: 0,
    },
    {
      recruiter: 'Almas',
      joined: 18,
      pending: 1,
    },
    {
      recruiter: 'Dhiraj',
      joined: 1,
      pending: 0,
    },
    {
      recruiter: 'Farheen',
      joined: 9,
      pending: 0,
    },
    {
      recruiter: 'Fazal',
      joined: 11,
      pending: 2,
    },
    {
      recruiter: 'Gausia',
      joined: 13,
      pending: 1,
    },
    {
      recruiter: 'Jhimi',
      joined: 7,
      pending: 1,
    },
    {
      recruiter: 'Khushi',
      joined: 13,
      pending: 1,
    },
    {
      recruiter: 'Madhanaaz',
      joined: 17,
      pending: 0,
    },
    {
      recruiter: 'Monika',
      joined: 9,
      pending: 1,
    },
    {
      recruiter: 'Nazneen',
      joined: 11,
      pending: 1,
    },
    {
      recruiter: 'Neha',
      joined: 21,
      pending: 0,
    },
    {
      recruiter: 'Nidhi',
      joined: 10,
      pending: 2,
    },
    {
      recruiter: 'Nikhil',
      joined: 1,
      pending: 0,
    },
    {
      recruiter: 'Nikita',
      joined: 14,
      pending: 1,
    },
    {
      recruiter: 'Nisha',
      joined: 3,
      pending: 0,
    },
    {
      recruiter: 'Prashant',
      joined: 1,
      pending: 0,
    },
    {
      recruiter: 'Rajas',
      joined: 3,
      pending: 0,
    },
    {
      recruiter: 'Renuka',
      joined: 13,
      pending: 2,
    },
    {
      recruiter: 'Rishabh',
      joined: 19,
      pending: 1,
    },
    {
      recruiter: 'Sattva',
      joined: 14,
      pending: 0,
    },
    {
      recruiter: 'Sheeba',
      joined: 8,
      pending: 0,
    },
    {
      recruiter: 'Shruti',
      joined: 6,
      pending: 0,
    },
    {
      recruiter: 'Steffi',
      joined: 18,
      pending: 0,
    },
    {
      recruiter: 'Tanzeem',
      joined: 9,
      pending: 2,
    },
    {
      recruiter: 'Viral',
      joined: 8,
      pending: 0,
    },
  ],
}*/
/* eslint-disable global-require */
mock.onGet('/admin/data').reply(() => [200, data])
