import mock from '@/@fake-db/mock'
import { paginateArray, sortCompare } from '@/@fake-db/utils'
import CandidateService from '@/services/candidate-service'

/* eslint-disable global-require */
/*const data = {
  candidates: [
    {
      id: 'SHC-1001',
      firstName: 'Ritesh Tiwari',
      lastName: '',
      companyName: 'XYZ',
      vendor: '',
      role: 'software-developer',
      candidatename: 'gslixby0',
      country: 'India',
      contact: '7875232791',
      email: 'ritz2488@gmail.com',
      currentPlan: 'Naukri',
      status: 'rejected',
      avatar: '',
      experience: '5',
      realtiveExp: '3',
      noticeperiod: '90',
      skills: 'A,B,C',
      cvPath: '',
      dob: '',
      source: 'Indeed',
      gender: '',
      reasonForChange: '',
      qualification: '',
      jobType: 'Permanent',
      currentCTC: '',
      expectedCTC: '',
      ctcNegotiable: '',
      comments: '',
      location: 'Pune',
      noOfJobChanges: '3',
      jobId: ['SHJ-1001,SHJ-1002'],
    },
    {
      id: 'SHC-1002',
      fullName: 'Yogesh Tiwari',
      company: 'XYZ',
      vendor: '',
      role: 'operations-head',
      candidatename: 'gslixby0',
      country: 'India',
      contact: '9923616186',
      email: 'yogesh@sattvahuman.com',
      currentPlan: 'Indeed',
      status: 'initiated',
      avatar: '',
      experience: '5',
      realtiveExp: '3',
      noticeperiod: '90',
      skills: 'A,B,C',
      cvPath: '',
      dob: '',
      source: 'Naukri',
      gender: '',
      reasonForChange: '',
      qualification: '',
      jobType: 'Permanent',
      currentCTC: '',
      expectedCTC: '',
      ctcNegotiable: '',
      comments: '',
      location: 'Pune',
      noOfJobChanges: '3',
      jobId: ['SHJ-1001,SHJ-1002'],
    },
    {
      id: 'SHC-1003',
      fullName: 'Nikita Tiwari',
      company: 'XYZ',
      vendor: '',
      role: 'hr',
      candidatename: 'Monster',
      country: 'India',
      contact: '1234567890',
      email: 'nikita@sattvahuman.com',
      currentPlan: 'Monster',
      status: 'joined',
      avatar: '',
      experience: '5',
      realtiveExp: '3',
      noticeperiod: '90',
      skills: 'A,B,C',
      cvPath: '',
      dob: '',
      source: 'Monster',
      gender: '',
      reasonForChange: '',
      qualification: '',
      jobType: 'Permanent',
      currentCTC: '',
      expectedCTC: '',
      ctcNegotiable: '',
      comments: '',
      location: 'Pune',
      noOfJobChanges: '3',
      jobId: ['SHJ-1001,SHJ-1002'],
    },
  ],
}*/
/* eslint-enable */

// ------------------------------------------------
// GET: Return Candidates
// ------------------------------------------------
mock.onGet('/apps/candidate/candidates').reply(config => {
  // eslint-disable-next-line object-curly-newline
  //const queryLowered='', filteredData=[], sortedData=[];
  
  const data = CandidateService.getAllCandidates('null').then(res => {
    if(res.status === "OK") {
      const data = {
        candidates: res.data
      }
      //console.log("mock real api",data.candidates)
      const {
        q = '',
        perPage = 10,
        page = 1,
        sortBy = 'id',
        sortDesc = false,
        //role = null,
        //plan = null,
        status = null,
      } = config.params
      /* eslint-enable */
    
      const queryLowered = q.toLowerCase()
      //console.log("query",queryLowered)
      const filteredData = data.candidates.filter(
        candidate =>
          //console.log(candidate)
          /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
          (candidate.first_name.toLowerCase().includes(queryLowered) || (candidate.first_name + ' ' + candidate.lastName).toLowerCase().includes(queryLowered)) &&
          //candidate.role === (role || candidate.role) &&
          //candidate.currentPlan === (plan || candidate.currentPlan) &&
          candidate.status === (status || candidate.status),
      )
      //console.log("fileterdata",filteredData)
      /* eslint-enable  */
    
      const sortedData = filteredData.sort(sortCompare(sortBy))
      //console.log("sortedData",sortedData)
      //console.log("paginate array",paginateArray(sortedData, perPage, page))
      //console.log("total",filteredData.length)
      if (sortDesc) sortedData.reverse() 
      //console.log("after if")

      return [
        200,
        {
          candidates: paginateArray(sortedData, perPage, page),
          total: filteredData.length,
        },
      ]
      
    }
    else {
      //console.log(res)
      return res
    }
  }).then(res => {
    //console.log("res",res)
    return res
  })
  //console.log(data)
      //console.log("data",data)
  return data;
})

// ------------------------------------------------
// POST: Add new candidate
// ------------------------------------------------
mock.onPost('/apps/candidate/candidates').reply(config => {
  // Get event from post data
  const { candidate } = JSON.parse(config.data)

  // Assign Status
  candidate.status = 'active'

  const { length } = data.candidates
  let lastIndex = 0
  if (length) {
    lastIndex = data.candidates[length - 1].id
  }
  candidate.id = lastIndex + 1

  data.candidates.push(candidate)

  return [201, { candidate }]
})

// ------------------------------------------------
// GET: Return Single Candidate
// ------------------------------------------------
mock.onGet(/\/apps\/candidate\/candidates\/\w+/).reply(config => {
  // Get event id from URL
  let candidateId = config.url.substring(config.url.lastIndexOf('/') + 1)
  //console.log(candidateId, typeof(candidateId))
  const id = candidateId//`SHC-${(1000 + parseInt(candidateId))}`
  //console.log(id)
  const data = CandidateService.getAllCandidates(id).then (res => {
    if(res.status === "OK") {
      return [200, res.data[0]]
      //return [200, candidate]
    }
    else {
      return [404, res]
      //return res
    }

  }).then(res => {
    //console.log("res",res)
    return res
  })
  return data
  // Convert Id to number
  // candidateId = Number(candidateId)

  //const candidateIndex = data.candidates.findIndex(e => e.id === candidateId)
  /*const candidate = data.candidates[candidateIndex]

  if (candidate) return [200, candidate]
  return [404]*/
})
