import axios from 'axios';
import AuthService from './auth-header';
import vertical from '@/navigation/vertical';
// /*dev*/const API_URL = 'http://192.168.1.34:5000/api/';
// /*prod*/ // const API_URL = 'https://ats.sattvahuman.in/api/';

let API_URL = ''
if (process.env.NODE_ENV === "production") {
    API_URL = 'https://ats.sattvahuman.in/api/';
} else {
    API_URL = 'http://192.168.1.34:5000/api/';
}
class CandidateService {
    async addCandidate(data) {
        try{
            const res = await axios.post(API_URL + 'addCandidate', data,
            { 
                headers: AuthService.authHeaderForm()
            });
            console.log(res)
            return res.data
        }
        catch(err) {
            //console.log("error",err.response)
            //console.log(res)
            return err.response
        }
    }

    async getAllCandidates(id) {
        try {
            if (id !== 'null') {
              const res = await axios.get(API_URL + 'candidates?candidate_id=' + id,
              { 
                headers: AuthService.authHeader()
              });
              return res.data
            } else {
              const res = await axios.get(API_URL + 'candidates',
              { 
                headers: AuthService.authHeader()
              });
              return res.data
            }
            //console.log(res.data)  
          }
          catch (err) {
            console.log(err.response);
            return err.response
          }
    }
    async getAllCandidatesData() {
        try {
            
              const res = await axios.get(API_URL + 'all-candidates',
              { 
                headers: AuthService.authHeader()
              });
              //console.log(res.data)
              return res.data
            
            //console.log(res.data)  
          }
          catch (err) {
            console.log(err.response);
            return err.response
          }
    }
    async getSimpleFilteredCandidates(query) {
        try {
            
              const res = await axios.get(API_URL + 'candidates/search/?query=' + query,
              { 
                headers: AuthService.authHeader()
              });
              //console.log(res.data)
              return res.data
            
            //console.log(res.data)  
          }
          catch (err) {
            console.log(err.response);
            return err.response
          }
    }

    async updateCandidate(id, data) {
        try{
            const res = await axios.put(API_URL + 'candidates/' + id, data,
            { 
                headers: AuthService.authHeader()
            });
            //console.log(res.data)
            return res.data
        }
        catch(err) {
            console.log(err.response);
            return err.response
        }
    }
    async updateCandidateViewed(id, data) {
        try{
            const res = await axios.put(API_URL + 'candidates/viewed/' + id, data,
            { 
                headers: AuthService.authHeader()
            });
            //console.log(res.data)
            return res.data
        }
        catch(err) {
            console.log(err.response);
            return err.response
        }
    }

    async updateCandidateStatus(cid, jid, data) {
        try{
            console.log(data)
            const res = await axios.put(API_URL + 'candidates/status/' + cid + '/' + jid, data,
            { 
                headers: AuthService.authHeader()
            });
            //console.log(res.data)
            return res.data
        }
        catch(err) {
            console.log(err.response);
            return err.response
        }
    }

    async deleteCandidate(id) {
        try{
            const res = await axios.delete(API_URL + 'candidates/' + id,
            { 
                headers: AuthService.authHeader()
            });
            //console.log(res.data)
            return res.data
        }
        catch(err) {
            console.log(err.response);
            return err.response
        }
    }

    async getMatchingCandidates(data) {
        try{
            const params = "?query=" + data.query + "&skill=" + data.skill +  "&source=" + data.source + "&qualification=" + data.qualification + "&location=" + data.location + "&exclude=" + data.exclude + "&gender=" + data.gender + "&minctc=" + data.minctc + "&maxctc=" + data.maxctc + "&minexp=" + data.minexp + "&maxexp=" + data.maxexp + "&minnp=" + data.minnp + "&maxnp=" + data.maxnp 
            const res = await axios.get(API_URL + 'candidates/advancedSearch/' + params,
            { 
                headers: AuthService.authHeader()
            });
            //console.log(res)
            return res.data
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }
    async removeCandidateFromJob(id, jobid) {
        try{
            const res = await axios.get(API_URL + 'candidate/jobs/' + id + '/' + jobid,
            { 
                headers: AuthService.authHeader()
            });
            //console.log(res)
            return res.data
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }
    async getCandidateCurrentStatus(id, jobid) {
        try{
            //console.log(id, jobid)
            const res = await axios.get(API_URL + 'candidate/status/' + id + '/' + jobid,
            { 
                headers: AuthService.authHeader()
            });
            //console.log(res)
            return res.data
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }
    async getMyCandidates(ids,data, startDate, endDate, revenue, cid, jid, dateField, jobStatus, client, jobType, payroll, mgr, category, vertical) {
        try{
            //console.log(ids, data)
            const res = await axios.get(API_URL + 'my-candidates?rec_ids=' + ids + '&role=' + data.role + '&rec=' + data.rec + '&status=' + data.status + '&sd=' + startDate + '&ed=' + endDate + '&revenue=' + revenue + '&cid=' + cid + '&jid=' + jid + '&dateField=' + dateField + '&jobStatus=' + jobStatus + '&client=' + client + '&jobType=' + jobType + '&payroll=' + payroll + '&mgr=' + mgr + '&category=' + category + '&vertical=' + vertical,
            { 
                headers: AuthService.authHeader()
            });
            //console.log(res)
            return res.data
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }
    async getCandidateViewData(id) {
        try{
            //console.log(id)
            const res = await axios.get(API_URL + 'candidates/view?candidate_id=' + id,
            { 
                headers: AuthService.authHeader()
            });
            //console.log(res)
            return res.data
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }
    async getCandidateJoinedListReadyForInvoice(client, category) {
        try{
            //console.log(client, category)
            const res = await axios.get(API_URL + 'candidates/list/invoice?client=' + client + '&category=' + category,
            { 
                headers: AuthService.authHeader()
            });
            console.log(res)
            return res.data
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }
    async checkCandidateExist(email,contact_no) {
        try{
            
            const res = await axios.get(API_URL + 'candidate/exist?email=' + email + '&contact_no=' + contact_no,
            { 
                headers: AuthService.authHeader()
            });
            console.log(res)
            return res.data
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }
    async uploadInfraCanAadhaar(data) {
        try{
            const res = await axios.post(API_URL + 'candidates/infra/docs/aadhaar', data,
            { 
                headers: AuthService.authHeaderForm()
            });
            console.log(res)
            return res
        }
        catch(err) {
            console.log("error",err.response)
            //console.log(res)
            return err.response
        }
    }
    async uploadInfraCanPan(data) {
        try{
            const res = await axios.post(API_URL + 'candidates/infra/docs/pan', data,
            { 
                headers: AuthService.authHeaderForm()
            });
            console.log(res)
            return res
        }
        catch(err) {
            console.log("error",err.response)
            //console.log(res)
            return err.response
        }
    }
    async uploadInfraCanCertificate(data) {
        try{
            const res = await axios.post(API_URL + 'candidates/infra/docs/certificate', data,
            { 
                headers: AuthService.authHeaderForm()
            });
            console.log(res)
            return res
        }
        catch(err) {
            console.log("error",err.response)
            //console.log(res)
            return err.response
        }
    }
    async uploadInfraCanPhoto(data) {
        try{
            const res = await axios.post(API_URL + 'candidates/infra/docs/photo', data,
            { 
                headers: AuthService.authHeaderForm()
            });
            console.log(res)
            return res
        }
        catch(err) {
            console.log("error",err.response)
            //console.log(res)
            return err.response
        }
    }
    async uploadInfraCanBank(data) {
        try{
            const res = await axios.post(API_URL + 'candidates/infra/docs/bank', data,
            { 
                headers: AuthService.authHeaderForm()
            });
            console.log(res)
            return res
        }
        catch(err) {
            console.log("error",err.response)
            //console.log(res)
            return err.response
        }
    }
    async getCandidateDocuments(id) {
        try{
            //console.log(id)
            const res = await axios.get(API_URL + 'candidates/infra/documents/' + id,
            { 
                headers: AuthService.authHeader()
            });
            //console.log(res)
            return res
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }
    
    //candidates/infra/documents/:id
}
export default new CandidateService();